import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function ChatImages({ images }: { images: string[] }) {

  return (
    <>
      {images.map((image) =>
          (
              <Zoom>
                <img src={`data:image/png;base64, ${image}`} className="img-fluid pt-2 pm-2" alt=""/>
              </Zoom>
          ))}
    </>
  );
}

export default ChatImages;
