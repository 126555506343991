import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ChatRequestState {
    userQuery: string,
    chatbotId: string,
    conversationId: string,
}

const initialState: ChatRequestState = {
    userQuery: "",
    chatbotId: "",
    conversationId: "",
}

export const chatRequestSlice = createSlice({
    name: "chatRequest",
    initialState,
    reducers: {
        setUserQuery: (state, action: PayloadAction<string>) => {
            state.userQuery = action.payload
        },
        setChatbotId: (state, action: PayloadAction<string>) => {
            state.chatbotId = action.payload
        },
        setConversationId: (state, action: PayloadAction<string>) => {
            state.conversationId = action.payload;
          },
    },
})

export const {
    setUserQuery,
    setChatbotId,
    setConversationId,
} = chatRequestSlice.actions;

export default chatRequestSlice.reducer;