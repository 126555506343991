import React, { useEffect, useRef } from 'react';
import './ChatMessage.css';
import { MDBSpinner } from 'mdb-react-ui-kit';
import Linkify from './generic/Linkify';
import { useTranslation } from 'react-i18next';
import ChatImages from './ChatImages';

function ChatMessage({ chatEntry, pending = false }: { chatEntry: ChatEntry; pending?: boolean }) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <div className={`d-flex ${chatEntry.role === 'AI' ? 'justify-content-start' : 'justify-content-end'}`}>
      {chatEntry.role === 'AI' ? (
        <div ref={ref} className="d-flex chat-message ai bg-white text-black left-bubble">
          <div className="align-self-start">
            <img src="/icons/ai.png" alt="AI Avatar" className="avatar-img" />
          </div>
          <div className="text-start">
            {pending ? <MDBSpinner size="sm" role="status" tag="span" className="me-2" /> : ''}
            {chatEntry.message.startsWith('error.') ? <React.Fragment>{t(chatEntry.message)}</React.Fragment> : <Linkify>{chatEntry.message}</Linkify>}
            {chatEntry.images ? <ChatImages images={chatEntry.images}></ChatImages> : ''}
          </div>
        </div>
      ) : (
        <div ref={ref} className="d-flex chat-message human bg-black border border-white text-white right-bubble">
          <div className="text-end">{chatEntry.message}</div>
          <div className="align-self-start">
            <img src="/icons/user.png" alt="Avatar" className="avatar-img" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatMessage;
