import React from "react";
import {MDBCard, MDBCardBody} from "mdb-react-ui-kit";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ChatMessage from "./ChatMessage";
import ChatFooter from "./ChatFooter";
import {RootState} from "../store/store";
import "./Chat.scss";

function Chat() {
    const {t} = useTranslation();
    const chatStatus = useSelector((state: RootState) => state.chat.status);
    const chatMessages = useSelector((state: RootState) => state.chat.messages);
    const conversationId = useSelector((state: RootState) => state.chat.activeConversationId);

    return (
        <MDBCard alignment='center' className="h-100">
            <MDBCardBody className="chat-body bg-black">
                {chatMessages && chatMessages.length
                    ? chatMessages.map((chat, index) => (
                        <ChatMessage key={index} chatEntry={chat}/>
                    ))
                    : ""}
                <div className={`d-flex justify-content-start ${chatStatus === "pending" ? "" : "d-none"}`}>
                    <ChatMessage chatEntry={{message: t("chat.typing"), role: "AI", conversationId}} pending={true}/>
                </div>
            </MDBCardBody>
            <ChatFooter/>
        </MDBCard>
    )
}

export default Chat;